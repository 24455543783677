import React from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import {createStyles, makeStyles} from "@material-ui/core/styles";
import styles from './styles.module.scss';


interface Props {
    onChange: (value: string) => void;
    value: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        textArea: {
            backgroundColor: '#4F2D5D',
            color: 'rgba(255,255,255,0.6)',
            fontSize: 16,
            border: 'none',
            padding: 20,
            flexGrow: 1,
        },
    }),
);

const Action = ({onChange, value}: Props): JSX.Element => {
    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange(event.currentTarget.value);
    };

    return (
        <form className={styles.form}>
            <TextareaAutosize
                className={`${classes.textArea} ${styles.textArea}`}
                aria-label="minimum height"
                rowsMin={5}
                placeholder="Describe your task..."
                onChange={handleChange}
                value={value}
            />
        </form>
    )
};

export default Action
