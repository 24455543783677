import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import styles from './styles.module.scss';
import Todo from "./Todo";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import AppBarComp from "./AppBar";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CompObjective from "./CompObjective";
import {
  getActionsForUser,
  getOrganization,
  getKeyResultsForObjective,
  getLatestActionForUser,
  completeAction,
  getOrganizationStatsV2,
  getKeyResultStatsV2,
  getActionsForKeyResult,
} from '../../api';

import Team from "./Team";
import Container from "../../layouts/Default";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  card: {
    marginTop: '10px',
  },

  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Dashboard = () => {
  const { organizationId } = useParams<any>();

  const classes = useStyles();
  const [screen, setScreen] = useState("you");
  const [level, setLevel] = useState('you');

  const [otherKeyResults, setOtherKeyResults] = useState([]);
  const [teamActions, setTeamActions] = useState([]);
  const [notCompletedTeamActions, setNotCompletedTeamActions] = useState(0);
  const [actions, setActions] = useState<any | null>([]);
  const [organization, setOrganization] = useState<any | null>(null);

  const [currentAction, setCurrentAction] = useState(null);
  const [organizationStats, setOrganizationStats] = useState(null);
  const [keyResultStats, setKeyResultStats] = useState(null);

  useEffect(() => {
    async function f() {
      const res = await getLatestActionForUser(organizationId);
      const json = await res.json();
      setCurrentAction(json.data);
    }

    f();
  }, [organizationId]);

  useEffect(() => {
    getOrganizationStatsV2(organizationId)
      .then(res => res.json())
      .then(({ data }) => {
        setOrganizationStats(data);
      }).catch(err => console.error(err));
  }, [organizationId]);


  useEffect(() => {
    if (currentAction) {
      getKeyResultStatsV2(currentAction.keyResultId)
        .then(res => res.json())
        .then(({ data }) => {
          setKeyResultStats(data.key_result);
        }).catch(err => console.error(err));
    }
  }, [currentAction]);

  useEffect(() => {
    getKeyResultsForObjective(1) // TODO: Should we get the key results for the objective you are currently working on instead?
      .then(res => res.json())
      .then(({ data }) => {
        setOtherKeyResults(data);
      }).catch(err => console.error(err));
  }, []);

  useEffect(() => {
    if (currentAction) {
      getActionsForKeyResult(currentAction.keyResultId)
        .then((res) => res.json())
        .then(json => {
          const teamActions = json.data;
          let c = 0;

          teamActions.forEach((a: any) => {
            if (!a.completedAt) {
              c++;
            }
          });

          setTeamActions(teamActions);
          setNotCompletedTeamActions(c);
        })
        .catch(err => console.error(err));
    }
  }, [currentAction]);


  useEffect(() => {
    Promise.all([
      getOrganization(organizationId),
    ]).then(values => {
      return Promise.all([
        values[0].json(),
      ]);
    }).then(values => {
      setOrganization(values[0].data);
    })
      .catch(err => console.error(err));
  }, [organizationId]);

  useEffect(() => {
    Promise.all([
      getActionsForUser(),
    ]).then(values => {
      return Promise.all([
        values[0].json(),
      ]);
    }).then(values => {
      setActions(values[0].data);
    })
      .catch(err => {
        console.error(err);
      })
  }, []);

  if (!(organization)) {
    return null;
  }

  const handleCompleteAction = async (actionId: number) => {
    await completeAction(actionId);
    const res = await getLatestActionForUser(organizationId);
    const json = await res.json();
    setCurrentAction(json.data);
  };

  const nextAction = currentAction;

  return (
    <Container>
      <div className={styles.header}>
        <h2>Good morning, <br /> <div className={styles.darkText}>this is your follow ups </div></h2>
      </div>
      <ToggleButtonGroup
        className={styles.toggleBox}
        value={level}
        exclusive
        onChange={(event, newLevel) => { setLevel(newLevel) }}
      >
        <ToggleButton
          onClick={() => {
            setScreen("you");
          }}
          className={screen === "you" && styles.selected}
          value="you" aria-label="you">
          <p>You</p>
        </ToggleButton>
        <ToggleButton
          onClick={() => {
            setScreen("team");
          }}
          className={screen === "team" && styles.selected}
          value="team" aria-label="team">
          <p>Your team</p>
        </ToggleButton>
        <ToggleButton
          onClick={() => {
            setScreen("company");
          }}
          className={screen === "company" && styles.selected}
          value="company" aria-label="company">
          <p>Company</p>
        </ToggleButton>
      </ToggleButtonGroup>

      { (screen === "you") &&
        <div className={styles.container}>
          <Todo
            action={nextAction}
            allActions={actions}
            onCompleteAction={handleCompleteAction}
          />
        </div>
      }

      { (screen === "team") &&
          <div className={styles.container}>
            <Team
                stats={keyResultStats}
                teamActions={teamActions}
                notCompletedTeamActions={notCompletedTeamActions}
                otherKeyResults={otherKeyResults}
            />
          </div>
      }
      { (screen === "company") &&
        <div className={styles.container}>
          <Card className={classes.card} >
            <CardContent>
              <Typography variant="h6" component="h2">
                PEOPLE
                        </Typography>
              <Typography variant="h4" component="h2">
                {organizationStats.participation.weeklyParticipationInPercentage}%
                        </Typography>
              <Typography className={classes.pos} color="textSecondary">
                participation rate
                        </Typography>
              <Typography variant="body2" component="p">
                this week - trending up!
                        </Typography>
            </CardContent>
          </Card>

          <Card className={classes.card} >
            <CardContent>
              <Typography variant="h6" component="h2">
                PURPOSE
              </Typography>
              <Typography variant="h4" component="h2">
                {organizationStats.countWeeklyActionsCompleted}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                actions completed
                        </Typography>
              <Typography variant="body2" component="p">
                Key results: 3 green / 1 yellow /0 red
                        </Typography>
            </CardContent>
          </Card>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" component="h2">
                PASSION
                        </Typography>
              <Typography variant="h4" component="h2">
                {organizationStats.countWeeklyValueLikes}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                value likes
                        </Typography>
              <Typography variant="body2" component="p">
                Engagement: high - trending up!
                        </Typography>
            </CardContent>
          </Card>

          <div>
            <h4>COMPANY OBJECTIVES</h4>
            <CompObjective stats={organizationStats} />
          </div>
        </div>
      }

      <AppBarComp />
    </Container>
  )
};

export default Dashboard
