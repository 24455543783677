function request(uri: string, method: string, data: any): Promise<Response> {
  return fetch(`${process.env.REACT_APP_API_URL}/${uri}`, {
    method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    referrerPolicy: 'no-referrer',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  });
}

export const sendMagicLink = (data: { email: string }) => {
  return request('send_magic_link', 'POST', data);
};

export const authenticate = (token: string) => {
  return request('authenticate', 'POST', { token });
};

export const getCurrentUser = () => {
  return request('users/me', "GET", undefined);
};

export const getOrganization = (organizationId: number) => {
  return request(`organizations/${organizationId}`, "GET", undefined);
};

export const createAction = (keyResultId: string, data: { description: string }) => {
  return request(`keyResults/${keyResultId}/actions`, "POST", data);
};

export const completeAction = (actionId: number) => {
  return request(`actions/${actionId}/complete`, "POST", {});
};

export const getActionsForUser = () => {
  return request(`actions`, "GET", undefined);
};

export const getActionsForTeam = () => {
  return request(`team/actions`, "GET", undefined);
};

export const getKeyResultsForObjective = (objectiveId: number) => {
  return request(`objectives/${objectiveId}/keyResults`, "GET", undefined);
};

export const getStatsForOrganization = (organizationid: number) => {
  return request(`organizations/${organizationid}/stats`, "GET", undefined);
};

export const joinOrganization = (participationCode: string) => {
  return request(`join`, "POST", { participationCode });
};

export const getOrganizationFeed = (organizationId: number) => {
  return request(`organizations/${organizationId}/feed`, "GET", undefined);
};

export const giveValueLike = (actionId: number, data: { valueType: string }) => {
  return request(`actions/${actionId}/valueLike`, 'POST', data);
};

export const getLatestActionForUser = (organizationId: number) => {
  return request(`organizations/${organizationId}/actions/latest`, "GET", undefined);
};

export const getOrganizationStatsV2 = (organizationId: number) => {
  return request(`organizations/${organizationId}/statsv2`, "GET", undefined);
};

export const getKeyResultStatsV2 = (keyResultId: number) => {
  return request(`keyResults/${keyResultId}/statsv2`, "GET", undefined);
};

export const getActionsForKeyResult = (keyResultId: number) => {
  return request(`keyResults/${keyResultId}/actions`, "GET", undefined);
};

