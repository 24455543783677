import React, {useEffect, useState} from 'react';
import styles from './styles.module.scss';
import AppBarComp from "../Dashboard/AppBar";
import {getOrganization} from '../../api';
import Container from "../../layouts/Default";

const Company = () => {

    const [strategy, setStrategy] = useState([]);

    useEffect(() => {
        getOrganization(1)
            .then(res => {
                return res.json();
            })
            .then(({data}) => {
                setStrategy(data.strategy)
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    return (
        <Container className={styles.company}>
            <h3 className={styles.title}>Company strategy</h3>
            <div className={styles.wrapper} style={{textAlign: 'left'}}>
                {strategy && strategy.map((data: any, i) => {
                    return (
                        <div key={i} className={styles.container}>
                            <p className={styles.subtitle}>{data.title}</p>
                            <p className={styles.text}>{data.text}</p>
                        </div>
                    );
                })}

                <div className={styles.container}>
                    <p className={styles.subtitle}>Values</p>
                    <h3>Be kind</h3>
                    <p>Treat people how you want to be treated.</p>
                    <h3>Dare to be excellent</h3>
                    <p>Be passionate about your craft and aim for a result that will make you - and everyone - proud.</p>
                    <h3>Love the struggle</h3>
                    <p>On most daring (sea) voyages we must expect troubled waters. That’s where we learn and create
                        greatness!</p>
                    <h3>Champion your colleagues</h3>
                    <p>Bring out the best in your colleague. Always seek understanding before arguing your own opinions.</p>
                    <h3>Empathise user, but own the solution</h3>
                    <p>We are the experts that solve the user problems</p>
                </div>
                <div className={styles.container}>
                    <p className={styles.subtitle}>Strategic objectives 2023</p>
                    <p>Hire more talent</p>
                 {/*   <ul>
                        <li>Hire a new biz director by June 1st</li>
                        <li>Go from 14 to 19 employees by end of year</li>
                        <li>Establish and run new onboarding process on 5 new hires by end of year</li>
                    </ul>*/}

                    <p>Design awesome products and brands</p>
                    {/*<ul>
                        <li>Partner with 1 venture capitalist company to get access to capital and increase our product
                            development pipeline
                        </li>
                        <li>Develop 3 products into next phase (Boligtilvalg - upgrade categories, Vendu - integrate
                            supertakst, TeamUp2 - test with client)
                        </li>
                        <li>Develop and present our product development offering to at least 7 customers</li>
                    </ul>
*/}
                    <p>Win the right projects</p>
                {/*    <ul>
                        <li>Go from 50% to 80% crossfunctional projects</li>
                        <li>Establish salesprocess and -pitch and present the”Uppercase sales pitch” to 20 potential
                            customers
                        </li>
                        <li>Win 3 new product development partnerships</li>
                    </ul>*/}

                    <p>Build uppercase 2.0</p>
                   {/* <ul>
                        <li>Carry out 7 social competency building events</li>
                        <li>Establish OKRs as management philosophy</li>
                    </ul>*/}
                    <br/>
                    <br/>
                </div>



            </div>
            <AppBarComp/>
        </Container>
    )
};

export default Company
