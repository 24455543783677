import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import { Button } from "@material-ui/core";
import { getOrganizationFeed, giveValueLike } from '../../api';
import AppBarComp from "../Dashboard/AppBar";
import Reactions from "./Reactions";
import styles from './Feed.module.css';
import Container from "../../layouts/Default";

const Feed = () => {

  const { organizationId } = useParams<any>();

  const [feed, setFeed] = useState([]);
  const [valueLikesShown, setValueLikesShown] = useState(-1);
  const [reactionText, setText] = useState('Value like');
  const [reactionIcon, setIcon] = useState(<ThumbUpAltIcon />);

  useEffect(() => {
    getOrganizationFeed(organizationId)
      .then(res => res.json())
      .then(({ data }) => {
        setFeed(data);
      }).catch(err => {
        console.error(err);
      });
  }, [organizationId]);

  const onGiveReaction = async (actionId: number, reaction: string) => {
    await giveValueLike(actionId, { valueType: reaction });
  };

  return (
    <Container>
      <h2>Weekly progress</h2>
      <Typography>
        Below you can see all the actions completed this week.
      </Typography>
      {feed.map((item: any, i) => {
        const userNameLowerCase = item.user.email.split('@' || '.')[0];
        const userName = userNameLowerCase.charAt(0).toUpperCase() + userNameLowerCase.slice(1);

        return (
          <Card className={styles.root} key={i}>
            <CardHeader
                avatar={
                  <Avatar aria-label="recipe">
                    {userName.split("")[0].toUpperCase()}
                  </Avatar>
                }
                action={
                  <IconButton aria-label="settings">
                  </IconButton>
                }
                title={userName + " is done with the task"}
                subheader={new Date(item.completedAt).toUTCString()}
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                <i>{userName}</i> has contributed to the objective "{item.key_result.objective.title}" by completing the action "{item.description}"
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <div className={styles.valueLikes}>
                <div className={styles.valueLikesReactions}>
                  {valueLikesShown === i &&
                    <Reactions
                      actionId={item.id}
                      valueLikes={item.value_likes}
                      onGiveReaction={onGiveReaction}
                      setIconCallback={icon => setIcon(icon)}
                      setTextCallback={text => setText(text)}
                    />
                  }
                  <Button
                    onClick={() => setValueLikesShown(i)}
                    type="submit"
                    startIcon={valueLikesShown === i ?
                        <div className={styles.reactionIcon} aria-label="recipe">
                          {reactionIcon}
                        </div>
                        : <ThumbUpAltIcon />}
                  >
                    {valueLikesShown === i ? reactionText : "Value like"}
                  </Button>
                </div>
              </div>
            </CardActions>
          </Card>
        );
      })}

      <div style={{ marginTop: '100px' }} />

      <AppBarComp />
    </Container >
  )
};

export default Feed;
