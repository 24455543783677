import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Radio from '@material-ui/core/Radio';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  card: {
    marginTop: '10px',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

interface Props {
  teamActions: any;
  notCompletedTeamActions: any;
  otherKeyResults: any;
  stats: any | null
}


const Team = ({
  teamActions,
  notCompletedTeamActions,
  otherKeyResults,
  stats,
}: Props): JSX.Element => {

  const classes = useStyles();

  if (!stats || !stats.participation) {
    return (
      <p>You need to create an action to show anything in this tab</p>
    );
  }

  return (
    <>
      <p>Your team has:</p>

      <Card className={classes.card} >
        <CardContent>
          <Typography variant="h6" component="h2">PEOPLE</Typography>
          <Typography variant="h4" component="h2">{stats.participation.weeklyParticipationInPercentage}%</Typography>
          <Typography className={classes.pos} color="textSecondary">participation rate</Typography>
          <Typography variant="body2" component="p">this week - trending up!</Typography>
        </CardContent>
      </Card>

      <Card className={classes.card} >
        <CardContent>
          <Typography variant="h6" component="h2">
            PURPOSE
                    </Typography>
          <Typography variant="h4" component="h2">
            {stats.countWeeklyActionsCompleted}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            actions completed
                    </Typography>
          <Typography variant="body2" component="p">
            Key results: 3 green / 1 yellow /0 red
                    </Typography>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" component="h2">
            PASSION
                    </Typography>
          <Typography variant="h4" component="h2">
            {stats.countWeeklyValueLikes}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            value likes
                    </Typography>
          <Typography variant="body2" component="p">
            Engagement: high - trending up!
                    </Typography>
        </CardContent>
      </Card>

      <h2 style={{ marginTop: '75px' }}>Remaining to-dos ({notCompletedTeamActions})</h2>
      {teamActions && teamActions.map((a: any, i: number) => {

        if (a.completedAt) {
          return null;
        }

        return (
          <Accordion key={i}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Radio checked={false} />
                <Typography>
                  A task assigned to {a.user.email}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">
                {a.description}
              </Typography>
            </AccordionDetails>
          </Accordion>
        )
      })}

      <h2 style={{ marginTop: '75px' }}>Other teams ({otherKeyResults.length})</h2>
      <Typography style={{ marginBottom: '10px' }} color="textSecondary">Working towards this objective</Typography>
      {otherKeyResults.map((kr: any, i: number) => {
        return (
          <Accordion key={i}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Radio checked={false} />
                <div style={{ textAlign: 'left' }}>
                  <Typography>
                    {kr.title}
                  </Typography>
                  <Typography color="textSecondary">
                    x participants
                                    </Typography>
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">
                {kr.description}
              </Typography>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </>
  )

};

export default Team
