import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';

import {
  authenticate,
  joinOrganization,
} from '../../api';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Authenticate = () => {
  const [authenticationFailed, setAuthenticationFailed] = useState<string | null>(null);
  const query = useQuery();
  const token = query.get("token");

  useEffect(() => {
    const handleAuthentication = async () => {
      try {
        if (!token) {
          console.error("Missing token from query");
          return;
        }

        let res = await authenticate(token);
        if (res.status === 401) {
          setAuthenticationFailed("Hmmm, looks like this magic link has expired — please try again!");
          return;
        }

        // Join the default organizations. Should not be hardcoded
        res = await joinOrganization("uc2021");
        window.location.replace(`/organizations/1/dashboard`);
      } catch (err) {
        console.error(err);
        setAuthenticationFailed("Hmmm, looks like something went wrong on our end — please try again or contact support!");
      }
    };

    handleAuthentication();
  }, [token]);

  return (
    <>
      <h1>Logging in..</h1>
      {authenticationFailed &&
        <Alert severity="error">Hmmm, looks like the magic link has expired — <a href="/login">Click here to try again!</a></Alert>
      }
    </>
  );
};

export default Authenticate;
