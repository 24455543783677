import React from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import {createStyles, makeStyles} from "@material-ui/core/styles";

interface Props {
  keyResults: Array<any>;
  onSelect: (n: string) => void;
  selected: string;
  className: string;
}

const useStyles = makeStyles(() =>
    createStyles({
      accordion: {
          textAlign: 'start',
          backgroundColor: '#4F2D5D',
          color: 'rgba(255,255,255, 0.5)',
          marginBottom: 4,
      },
    }),
);

const Kr = ({ keyResults, onSelect, selected, className }: Props): JSX.Element => {
  const classes = useStyles();

  const handleChange = (event: any) => {
    onSelect(`${event.currentTarget.value}`);
  };

  return (
    <>
      <FormControl className={className} component="fieldset">
        <RadioGroup name="objective" value={selected} onChange={(handleChange)}>
          {keyResults.map((keyResult, i) => {
            return (
              <Accordion className={classes.accordion} key={i}>
                <AccordionSummary
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                >

                  <FormControlLabel
                    aria-label="Acknowledge"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={<Radio color="default" />}
                    label={keyResult.title}
                    value={`${keyResult.id}`}
                  />

                </AccordionSummary>
              </Accordion>
            )
          })}

        </RadioGroup>
      </FormControl>
    </>
  )
};

export default Kr
