import React, { useEffect, useRef, useState } from 'react';
import styles from './Reactions.module.css';
import { Button } from "@material-ui/core";

function getIconByType(type: string) {
  switch (type) {
    case "CHAMPION_YOUR_COLLEAGUES":
      return "CC";
    case "BE_KIND":
      return "BK";
    case "LOVE_THE_STRUGGLE":
      return "LS";
    case "DARE_TO_BE_EXCELLENT":
      return "DE";
    case "EMPATHISE_USER":
      return "EU";
    default:
      return null;
  }
}


interface Props {
  actionId: number;
  valueLikes: Array<any>;
  onGiveReaction: (actionId: number, type: string) => void;
  setIconCallback: (icon: any) => void;
  setTextCallback: (text: any) => void;
}


const Reactions = ({
  valueLikes,
  onGiveReaction,
  actionId,
  setIconCallback,
  setTextCallback,
}: Props): JSX.Element => {

  const [close, setClose] = useState(false);

  const defaultReactions = {
    'CHAMPION_YOUR_COLLEAGUES': 0,
    'BE_KIND': 0,
    'LOVE_THE_STRUGGLE': 0,
    'DARE_TO_BE_EXCELLENT': 0,
    'EMPATHISE_USER': 0,
  };

  valueLikes.forEach((vl: any) => {
    let value = defaultReactions[vl.valueType];
    defaultReactions[vl.valueType] = value += 1;
  });


  const [countNumReactions, setCountNumReactions] = useState(defaultReactions);

  function useOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setClose(true)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  const entries = Object.entries(countNumReactions);


  const handleOnGiveReaction = (actionId, valueType) => {
    onGiveReaction(actionId, valueType);
    setIconCallback(getIconByType(valueType));
    setTextCallback(valueType);

    // Update reactions counter
    const reactions = { ...countNumReactions };
    reactions[valueType] = reactions[valueType] + 1;
    setCountNumReactions(reactions);
  };

  return (
    <div className={!close ? styles.container : styles.non} ref={wrapperRef}>

      {entries.map((entry: any, i: number) => {
        const [valueType, count] = entry;

        return (
          <Button
            className={styles.reactionBtn}
            key={i}
            onClick={() => { handleOnGiveReaction(actionId, valueType) }}
            type="submit"
            startIcon={
              <div className={styles.reactionIcon} aria-label="recipe">
                {getIconByType(valueType)}
              </div>
            }
          >
            <span className={styles.hoverText}>{valueType}</span>
            <span className={styles.count}>{count}</span>
          </Button>
        );
      })}
    </div>
  )
};

export default Reactions
