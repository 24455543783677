import React, {useState, useEffect} from 'react';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import {useParams} from 'react-router-dom';

import {Button} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {getCurrentUser, getOrganization, createAction} from '../../api';

import Objective from "./Objective";
import Action from "./Action";
import Dashboard from "../Dashboard";
import Kr from "./Kr";

import styles from './styles.module.scss';
import Header from "./Header";
import Container from "../../layouts/Default";

const useStyles = makeStyles(() =>
    createStyles({
        btn: {
            backgroundColor: '#FFFFFF',
            color: '#4A4B7F',
            borderRadius: 50,
            marginTop: 30,
            marginBottom: 30,
            padding: 20,
            fontWeight: 'bold',
        },
        btnBack: {
            color: 'black',
            border: 'none',
            display: 'flex',
        },
    }),
);

const Onboard = () => {
    const classes = useStyles();
    const {organizationId} = useParams<any>();
    const [step, setStep] = useState(0);
    const [currentUser, setCurrentUser] = useState<any | null>(null);
    const [organization, setOrganization] = useState<any | null>(null);
    const [error, setError] = useState(false);

    const [objectiveId, setObjectiveId] = useState("");
    const [keyResultId, setKeyResultId] = useState("");
    const [action, setAction] = useState("");
    const userNameLowerCase = currentUser && currentUser.email.split('@' || '.')[0];
    const userName = userNameLowerCase && userNameLowerCase.charAt(0).toUpperCase() + userNameLowerCase.slice(1);

    useEffect(() => {
        Promise.all([
            getCurrentUser(),
            getOrganization(organizationId),
        ]).then(values => {
            return Promise.all([
                values[0].json(),
                values[1].json(),
            ]);
        }).then(values => {
            setCurrentUser(values[0].data);
            setOrganization(values[1].data);
            setError(false);
        }).catch(err => {
            console.error(err);
            setError(true);
        })
    }, [organizationId]);

    const handleCreateAction = async () => {
        await createAction(keyResultId, {description: action});
        setStep(step + 1)
    };

    if (!(organization && currentUser)) {
        return null;
    }

    if (step === 0) {
        return (
            <Container darkTheme={true}>
                <Header step={step + 1} username={userName} description={'Select which objective you want to contribute to:'}/>
                <Objective
                    objectives={organization ? organization.objectives : []}
                    onSelect={(n) => setObjectiveId(n)}
                    selected={objectiveId}
                />
                <div className={styles.containerBtn}>
                    <Button
                        onClick={() => {
                            setStep(step + 1)
                        }}
                        disabled={!objectiveId}
                        type="submit"
                        variant="outlined"
                        className={classes.btn}
                        style={{width: "80%"}}
                    >
                        Continue
                    </Button>
                    <Button
                        style={{color: "rgba(255,255,255, 0.5)"}}
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = `/organizations/${organizationId}/dashboard`;
                        }}
                    >
                        Cancel
                    </Button>
                {error &&
                <Alert severity="error">Something went wrong — please contact support!</Alert>
                }
                </div>
            </Container>
        );
    }

    if (step === 1) {

        const objective = organization.objectives.find((objective: any) => objective.id === parseInt(objectiveId));

        return (
            <Container darkTheme={true}>
                <Button
                    className={classes.btnBack}
                    onClick={() => {
                        setStep(step - 1)
                    }}
                    type="button"
                    variant="outlined"
                    color="primary"
                    startIcon={<ArrowBackIcon/>}

                />
                <Header step={step + 1} username={userName} description={'Now select the key result you will contribute to this week'}/>
                <div className={styles.reminderBox}>SELECTED OBJECTIVE: <br/> {objective.title}</div>
                <Kr
                    className={styles.fullWidth}
                    keyResults={objective.key_results}
                    onSelect={(n) => setKeyResultId(n)}
                    selected={keyResultId}
                />
                <div className={styles.containerBtn}>
                    <Button
                        onClick={() => {
                            setStep(step + 1)
                        }}
                        disabled={!keyResultId}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        className={classes.btn}
                        style={{width: "80%"}}
                    >
                        Continue
                    </Button>
                    <Button
                        style={{color: "rgba(255,255,255, 0.5)"}}
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = `/organizations/${organizationId}/dashboard`;
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </Container>
        )
    }

    if (step === 2) {
        const objective = organization.objectives.find((objective: any) => objective.id === parseInt(objectiveId));

        return (
            <Container darkTheme={true}>
                <Button
                    className={classes.btnBack}
                    onClick={() => {
                        setStep(step - 1)
                    }}
                    type="button"
                    variant="outlined"
                    color="primary"
                    startIcon={<ArrowBackIcon/>}

                />
                <Header
                    step={step + 1}
                    username={userName}
                    description={'Now, tell us what you will be doing to help us achieve the objective\n' +
                    objective.title}
                />
                <Action
                    onChange={(s) => setAction(s)}
                    value={action}
                />
                <div className={styles.containerBtn}>
                    <Button
                        onClick={handleCreateAction}
                        disabled={!action.length}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        className={classes.btn}
                        style={{width: "80%"}}
                    >
                        Finish
                    </Button>
                    <Button
                        style={{color: "rgba(255,255,255, 0.5)"}}
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = `/organizations/${organizationId}/dashboard`;
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </Container>
        );
    }

    return (
        <Dashboard/>
    );

};

export default Onboard
