import { BrowserRouter, Switch, Route } from "react-router-dom";

import Onboard from "./components/Onboard";
import Login from "./components/Login";
import Authenticate from "./components/Authenticate";
import Company from "./components/Company";
import Dashboard from "./components/Dashboard";
import Feed from "./components/Feed";
import Landing from "./components/Landing";
import NotFound from './components/NotFound';


function App() {
  return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/authenticate">
            <Authenticate />
          </Route>
          <Route path="/organizations" component={OrganizationsRoutes} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
  );
}

const OrganizationsRoutes = () => {
  return (
    <Switch>
      <Route exact path="/organizations/:organizationId" component={Onboard} />
      <Route path="/organizations/:organizationId/dashboard" component={Dashboard} />
      <Route path="/organizations/:organizationId/strategy" component={Company} />
      <Route path="/organizations/:organizationId/feed" component={Feed} />
    </Switch>
  );
};

export default App;
