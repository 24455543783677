import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '63.33%',
    flexShrink: 0,
    textAlign: 'left',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    textAlign: 'left',
  },
  description: {
    textAlign: 'left',
  }
}));

interface Props {
  stats: any | null
}

const CompObjective = ({ stats }: Props) => {
  const classes = useStyles();
  const contributions = stats ? stats.contributionToObjectives : [];

  return (
    <div className={classes.root}>
      {contributions.map((c: any, i: number) => {
        return (
          <Accordion key={i}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>{c.objective.title}</Typography>
              <Typography className={classes.secondaryHeading}>{c.numContributors} contr</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.description}>
                {c.objective.description}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default CompObjective
