import React, {useState} from 'react';

import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';

import {sendMagicLink} from '../../api';
import Container from "../../layouts/Default";
import {createStyles, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
    createStyles({
        input: {
            marginTop: '1.5rem',
            padding: '0.8rem 1rem',
            width: '100%',
            backgroundColor: '#4F2D5D',
            color: 'rgba(255,255,255, 0.5)',
        },
        link: {
            color: '#FFFFFF',
            opacity: 0.5,
            textTransform: 'uppercase',
        },
        btn: {
            fontFamily: ' Montserrat, sans-serif',
            fontWeight: 700,
            backgroundColor: '#FFFFFF',
            color: '#4A4B7F',
            borderRadius: 50,
            marginTop: 30,
            padding: '0.8rem',
            width: '80%',
        },
    }),
);


const Login = () => {
    const classes = useStyles();

    const [email, setEmail] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [error, setError] = useState(false);

    const onChangeEmail = (e: any) => {
        setEmail(e.target.value);
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        handleSendMagicLink();
    };

    const handleSendMagicLink = async () => {
        try {
            await sendMagicLink({email});
            setEmailSent(true);
        } catch (err) {
            console.error(err);
            setError(true);
        }
    };

    if (emailSent) {
        return (
            <Container darkTheme={true}>
                <h2>Hello there!  <br/> Let’s figure out where  you belong..</h2>
                <p>
                    We sent an email to you at {email}. It has a magic link that'll instantly sign you in.
                </p>
            </Container>
        );
    }

    return (
        <Container darkTheme={true}>
            <h2>Hello there!  <br/> Let’s figure out where  you belong..</h2>
            <form onSubmit={onSubmit}>
                <Input className={classes.input} placeholder="Add your work e-mail" type="email" required={true}
                       onChange={onChangeEmail}/>
                <br/>
                <br/>
                <Button
                    disabled={!email}
                    type="submit"
                    variant="outlined"
                    color="primary"
                    className={classes.btn}
                >
                    Send me a magic link
                </Button>
            </form>
            <br/>
            {error &&
            <Alert severity="error">Hmmm, something went wrong — please try again!</Alert>
            }
        </Container>
    );
};

export default Login;
