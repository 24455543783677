import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './Todo.module.css';
import Link from '@material-ui/core/Link';
import CompletedTask from "./CompletedTask";
import { makeStyles } from '@material-ui/core/styles';


interface Props {
    action: any;
    allActions: Array<any>;
    onCompleteAction: (actionId: number) => void;
}


const useStyles = makeStyles({
    accordion: {
        borderRadius: 12,
        "&.MuiAccordion-root:before": {
            backgroundColor: "#F1F7FC"
        }
    },
});

const Todo = ({onCompleteAction, action, allActions}: Props): JSX.Element => {
    const classes = useStyles();

    const {organizationId} = useParams<any>();
    const [complete, setComplete] = useState(false);

    const handleCompleteAction = async () => {
        onCompleteAction(action.id);
        setComplete(true);
    };

    return (
        <div className={styles.accordionContainer}>
            <p>Your weekly focus</p>
            <Accordion
                className={classes.accordion}
            >
                <AccordionSummary
                    disabled={true}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                >
                    No weekly focus set, pick one now!
                </AccordionSummary>
            </Accordion>

            <p>Your tasks</p>
            {(!(complete) && action && !action.completedAt) ?
                <Accordion
                    className={classes.accordion}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"

                    >
                        <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={<Checkbox onChange={handleCompleteAction}/>}
                            label={`${action.description}`}
                        />
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography color="textSecondary" className={styles.textContainer}>
                            <b>Objective:</b> {action && action.objective && action.objective.title}
                            <br/>
                            <b> Key result: </b> {" "}
                            {action && action.keyResult && action.keyResult.title}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                :
                <p>Awesome job you have completed your to-dos!</p>
            }
            <div>
                <br/>
                <Link href={`/organizations/${organizationId}/`}> Click here to create a new task</Link>
            </div>

            <CompletedTask
                complete={complete}
                allActions={allActions}
            />

        </div>
    )
};

export default Todo
