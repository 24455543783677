import React from 'react';

import styles from './styles.module.scss';

interface Props {
    username: string;
    step: number;
    description: string;
}

const Header = ({username, step, description}: Props): JSX.Element => {
    return (
        <div className={styles.headerContainer}>
            <h2 className={styles.header}>Hi {username}!</h2>
            <p className={styles.step}>step {step} of 3</p>
            <p className={styles.description}>{description}</p>

        </div>
    )
};

export default Header
