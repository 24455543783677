import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import {makeStyles} from "@material-ui/core/styles";

interface Props {
    complete: boolean;
    allActions: Array<any>;
}


const useStyles = makeStyles({
    accordion: {
        borderRadius: 12,
        "&.MuiAccordion-root:before": {
            backgroundColor: "#F1F7FC"
        }
    },
});

const CompletedTask = ({complete, allActions}: Props): JSX.Element => {
    const classes = useStyles();

    return (
        <>
            <p>Completed tasks</p>
            {complete &&
            <Accordion
                className={classes.accordion}
            >
                <AccordionSummary
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Radio checked={false}/>
                        <div style={{textAlign: 'left'}}>
                            <Typography color="textSecondary">
                                {allActions[0].description}
                            </Typography>
                        </div>
                    </div>
                </AccordionSummary>
            </Accordion>
            }

            {allActions.map((a, i) => {
                if (!a.completedAt) return null;
                return (
                    <Accordion key={i}>
                        <AccordionSummary
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="additional-actions1-header"
                        >
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <Radio checked={false}/>
                                <div style={{textAlign: 'left'}}>
                                    <Typography color="textSecondary">
                                        {a.description}
                                    </Typography>
                                </div>
                            </div>
                        </AccordionSummary>
                    </Accordion>
                );
            })}
        </>
    )
};

export default CompletedTask
