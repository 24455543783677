import React from "react";
import {createStyles, makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


interface Props {
    objectives: Array<any>;
    onSelect: (n: string) => void;
    selected: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        accordion: {
            textAlign: 'start',
            backgroundColor: '#4F2D5D',
            color: 'rgba(255,255,255, 0.5)',
            marginBottom: 4,
            boxShadow: 'none',
        },
    }),
);

const Objective = ({objectives, onSelect, selected}: Props): JSX.Element => {
    const classes = useStyles();
    const handleChange = (event: any) => {
        onSelect(`${event.currentTarget.value}`);
    };
    return (
        <FormControl component="fieldset">
            <RadioGroup name="objective" value={selected} onChange={(handleChange)}>
                {objectives.map((objective: any, i: number) => {
                    return (
                        <Accordion className={classes.accordion} key={i}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-label="Expand"
                                aria-controls="additional-actions1-content"
                                id="additional-actions1-header"
                            >
                                <FormControlLabel
                                    aria-label="Acknowledge"
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    control={<Radio color="default"/>}
                                    label={objective.title}
                                    value={`${objective.id}`}
                                />

                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <span>Key results for the objective</span>
                                    <ol>
                                        {objective.key_results.map((keyResult: any, i: number) => {
                                            return (
                                                <li key={i}>{keyResult.title}</li>
                                            )
                                        })}
                                    </ol>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}

            </RadioGroup>
        </FormControl>
    )
};

export default Objective
