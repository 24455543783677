import { useState } from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import Container from "../../layouts/Default";

const Landing = () => {

  const history = useHistory();
  const [cookie] = useState<string | undefined>(Cookies.get('connect.sid'));

  if (cookie) {
    history.push('/organizations/1/dashboard'); // TODO: Replace hardcoded organizationId
  }

  return (
    <Container>
      <h2>
        Welcome to Team Up 2!
      </h2>
      <p>Log in with your email to get started</p>
      <a href="/login">Get started</a>
    </Container>
  );
};

export default Landing;
