import React from 'react';
import {useParams} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import CheckIcon from '@material-ui/icons/Check';
import styles from './styles.module.scss';

const useStyles = makeStyles(() => ({
    icon: {
        padding: 0,
        margin: 0,
    },
    appBar: {
        backgroundColor: '#FFFFFF',
        top: 'auto',
        bottom: 0,
        paddingTop: 10,
    },
    toolBar: {
        display: 'flex',
        justifyContent: 'space-around'

    },
    fabButton: {
        position: 'absolute',
        zIndex: 1,
        top: -30,
        left: 0,
        right: 0,
        margin: '0 auto',
    },
}));

const AppBarComp = () => {
    const params = useParams<any>();
    const classes = useStyles();

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
                <div className={styles.btnWrapper}>
                    <IconButton
                        className={classes.icon}
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = `/organizations/${params.organizationId}/feed`;
                        }}
                        edge="start" aria-label="open drawer">
                        <NoteOutlinedIcon color="disabled"/>
                    </IconButton>
                    <p>Progress</p>
                </div>
                <div className={styles.btnWrapper}>
                    <IconButton
                        className={classes.icon}
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = `/organizations/${params.organizationId}/dashboard`;
                        }}
                        edge="start" aria-label="open drawer">
                        <CheckIcon color="disabled"/>
                    </IconButton>
                    <p>follow-ups</p>
                </div>

                <div className={styles.btnWrapper}>
                    <IconButton
                        className={classes.icon}
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = `/organizations/${params.organizationId}/strategy`;
                        }}
                        edge="start" aria-label="open drawer">
                        <TrackChangesIcon color="disabled"/>
                    </IconButton>
                    <p>strategy</p>
                </div>
            </Toolbar>
        </AppBar>
    )
};

export default AppBarComp
