import React from 'react';
import styles from './styles.module.scss';

interface Props {
    children: any;
    darkTheme?: boolean;
    className?: string;
}

const Container = ({children, darkTheme, className}: Props): JSX.Element => {
    return (
        <div className={`${styles.container} ${darkTheme && styles.dark} ${className}`}>
            {children}
        </div>
    )
};

export default Container
